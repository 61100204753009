/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import DashboardView from './DashboardView';
import dashboardImage from '../../../assets/images/dashboard/graphic-dashboard-move-money@2x.png';

const MoveMoneyDashboard = ({ customerId, transferId }) => {
  const dashboardProps = {
    ...(customerId != null &&
      transferId != null && {
        link: `https://dashboard-sandbox.dwolla.com/customers/${customerId}/transactions?page=1&transfer=${transferId}`,
      }),
  };
  return <DashboardView image={dashboardImage} {...dashboardProps} />;
};

MoveMoneyDashboard.propTypes = {
  customerId: PropTypes.string,
  transferId: PropTypes.string,
};

MoveMoneyDashboard.defaultProps = {
  customerId: '',
  transferId: '',
};

export default MoveMoneyDashboard;
