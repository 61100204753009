import React from 'react';
import Preloader from '../../atoms/preloader/Preloader';
import styles from './LoadingScreen.module.scss';

const LoadingScreen = () => (
  <div className={styles.container}>
    <Preloader />
  </div>
);

export default LoadingScreen;
