import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './PayoutsIcon.module.scss';
import { ReactComponent as PayoutsSvg } from '../../../assets/images/phone-interface/icon-payout.svg';
import { themeOptions } from '../../../utils/propTypeHelper';

const PayoutsIcon = ({ theme, style }) => (
  <PayoutsSvg
    className={classNames(styles[theme], {
      [style]: style,
    })}
  />
);

PayoutsIcon.propTypes = {
  theme: PropTypes.oneOf(themeOptions).isRequired,
  style: PropTypes.string,
};

PayoutsIcon.defaultProps = {
  style: null,
};

export default PayoutsIcon;
