/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import DashboardView from './DashboardView';
import dashboardImage from '../../../assets/images/dashboard/graphic-dashboard-create-user@2x.png';

const CreateUserDashboard = ({ customerId }) => {
  const dashboardProps = {
    ...(customerId != null && {
      link: `https://dashboard-sandbox.dwolla.com/customers/${customerId}/details`,
    }),
  };
  return <DashboardView image={dashboardImage} {...dashboardProps} />;
};

CreateUserDashboard.propTypes = {
  customerId: PropTypes.string,
};

CreateUserDashboard.defaultProps = {
  customerId: null,
};

export default CreateUserDashboard;
