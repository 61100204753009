const moneyPattern = /^[0-9.]\d*(((\d{3}){1})?(\.\d{0,2})?)$/;

export const isPopulated = val => !!(val && val !== '');
export const isEmail = val => val.includes('@');

export const isValidRoutingNumber = routing => {
  if (routing.length !== 9) {
    return false;
  }

  // http://en.wikipedia.org/wiki/Routing_transit_number#MICR_Routing_number_format
  const checksumTotal =
    7 *
      (parseInt(routing.charAt(0), 10) +
        parseInt(routing.charAt(3), 10) +
        parseInt(routing.charAt(6), 10)) +
    3 *
      (parseInt(routing.charAt(1), 10) +
        parseInt(routing.charAt(4), 10) +
        parseInt(routing.charAt(7), 10)) +
    9 *
      (parseInt(routing.charAt(2), 10) +
        parseInt(routing.charAt(5), 10) +
        parseInt(routing.charAt(8), 10));

  return checksumTotal % 10 === 0;
};

export const isValidDollarAmount = amount => {
  if (typeof amount !== 'string' && typeof amount !== 'number') return false;

  return moneyPattern.test(amount.toString()) && Number(amount) > 0;
};

export const isValidMicroDepositAmount = amount => {
  if (!isValidDollarAmount(amount)) return false;

  return Number(amount) < 0.1;
};

export const areValidMicroDepositAmounts = (amount1, amount2) => {
  const isSandboxMaxAttemptsAmt = a => Number(a) === 0.09;

  return (
    isValidMicroDepositAmount(amount1) &&
    isValidMicroDepositAmount(amount2) &&
    !(isSandboxMaxAttemptsAmt(amount1) && isSandboxMaxAttemptsAmt(amount2))
  );
};

export const isAmountCoveredByBalance = (amount, sourceUrl, fundingSources) => {
  const isBalance = fs => fs.type === 'balance';

  const selectedFs = fundingSources.find(fs => fs.value === sourceUrl);

  return (
    !!selectedFs &&
    (!isBalance(selectedFs) || Number(amount) <= Number(selectedFs.balance))
  );
};

export const areSourceAndDestinationDifferent = (sourceUrl, destinationUrl) => {
  return sourceUrl !== destinationUrl;
};
