import { connect } from 'react-redux';
import { resetData } from '../../../redux/reducer';
import getPageVariation from '../../../utils/page-variations';
import config from '../../../config';

const mapDispatchToProps = {
  resetData,
};

export default connect(
  null,
  mapDispatchToProps
)(getPageVariation('conclusion', config.versionName));
