import React from 'react';
import { Container, Button } from 'react-bootstrap';
import styles from './MobileGuard.module.scss';

const MobileGuard = () => (
  <div className={styles.mobileGuard} aria-hidden="true">
    <Container>
      <div className={styles.content}>
        <div className={styles.message}>
          The Dwolla API Demo is currently available only on desktop view
        </div>
        <a href="https://www.dwolla.com/">
          <Button variant="outline-light">Return to dwolla.com</Button>
        </a>
      </div>
    </Container>
  </div>
);

export default MobileGuard;
