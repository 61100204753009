import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ThemeButton from '../../../atoms/theme-button/ThemeButton';
import { themeOptions } from '../../../../utils/propTypeHelper';
import { Heading } from '../../../atoms/typography/Typography';
import styles from './SendFundsScreen.module.scss';
import ProfileIcon from '../../../atoms/icons/ProfileIcon';
import formatCurrency from '../../../../utils/currency';

const fundingSourceName = fs => {
  return fs.balance ? `${fs.name} - ${formatCurrency(fs.balance)}` : fs.name;
};

const SendFundsScreen = ({
  theme,
  isValidInput,
  pending,
  elToFocus,
  showAdminHeader,
  headingPreText,
  headingText,
  sendButtonText,
  fundingSources,
  sourceUrl,
  setSourceUrl,
  fundingDestinations,
  destinationUrl,
  setDestinationUrl,
  amount,
  setAmount,
  handleSubmitClicked,
}) => (
  <>
    {showAdminHeader && (
      <div className={styles.admin}>
        <p>Admin</p>
        <ProfileIcon />
      </div>
    )}

    <Heading preText={headingPreText} variant="theme" theme={theme} center>
      {headingText}
    </Heading>

    <Form>
      <Form.Group controlId="source">
        <Form.Label>
          {fundingDestinations.length ? 'Transfer From:' : 'Funding Source:'}
        </Form.Label>
        <Form.Control
          as="select"
          onChange={e => setSourceUrl(e.target.value)}
          value={sourceUrl}
          ref={elToFocus}
        >
          <option aria-label="None" />
          {fundingSources.map(fs => (
            <option value={fs.value}>{fundingSourceName(fs)}</option>
          ))}
        </Form.Control>
      </Form.Group>
      {!!fundingDestinations.length && (
        <Form.Group controlId="destination">
          <Form.Label>Transfer To:</Form.Label>
          <Form.Control
            as="select"
            onChange={e => setDestinationUrl(e.target.value)}
            value={destinationUrl}
          >
            <option aria-label="None" />
            {fundingDestinations.map(fs => (
              <option value={fs.value}>{fundingSourceName(fs)}</option>
            ))}
          </Form.Control>
        </Form.Group>
      )}
      <Form.Group controlId="amount">
        <Form.Label>Amount:</Form.Label>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text>$</InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            type="number"
            step="0.01"
            value={amount}
            onChange={e => setAmount(e.target.value)}
          />
        </InputGroup>
      </Form.Group>
      <ThemeButton
        theme={theme}
        disabled={!isValidInput}
        pending={pending}
        onClick={handleSubmitClicked}
      >
        {sendButtonText}
      </ThemeButton>
    </Form>
  </>
);

SendFundsScreen.propTypes = {
  theme: PropTypes.oneOf(themeOptions).isRequired,
  isValidInput: PropTypes.bool,
  pending: PropTypes.bool,
  elToFocus: PropTypes.element,
  showAdminHeader: PropTypes.bool,
  headingPreText: PropTypes.string,
  headingText: PropTypes.string.isRequired,
  sendButtonText: PropTypes.string,
  fundingSources: PropTypes.arrayOf(PropTypes.object),
  sourceUrl: PropTypes.string,
  setSourceUrl: PropTypes.func,
  fundingDestinations: PropTypes.arrayOf(PropTypes.object),
  destinationUrl: PropTypes.string,
  setDestinationUrl: PropTypes.func,
  amount: PropTypes.number,
  setAmount: PropTypes.func,
  handleSubmitClicked: PropTypes.func,
};

SendFundsScreen.defaultProps = {
  isValidInput: false,
  pending: false,
  elToFocus: null,
  showAdminHeader: false,
  headingPreText: '',
  sendButtonText: 'Send',
  fundingSources: [],
  sourceUrl: '',
  setSourceUrl: null,
  fundingDestinations: [],
  destinationUrl: '',
  setDestinationUrl: null,
  amount: 0,
  setAmount: null,
  handleSubmitClicked: null,
};

export default SendFundsScreen;
