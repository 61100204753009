import React from 'react';
import { ReactComponent as ArrowIcon } from '../../../assets/images/icons/icon-arrow.svg';
import Note from './Note';

const PlaidCredentialsNote = () => (
  <Note>
    <ArrowIcon />
    &nbsp; &nbsp; Username: &nbsp;<code>user_good</code> &nbsp;|&nbsp; Password:
    &nbsp;<code>pass_good</code>
  </Note>
);

export default PlaidCredentialsNote;
