import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form } from 'react-bootstrap';
import styles from './AchCalculatorInput.module.scss';
import { formatCurrencyWithoutDollarSign } from '../../../utils/currency';
import totalFor from '../../../utils/ach-calculator';

const AchCalculatorInput = ({
  paymentFlow,
  cashAndCheck,
  setCashAndCheck,
  achCredit,
  setAchCredit,
  achDebit,
  setAchDebit,
  wire,
  setWire,
  creditCard,
  setCreditCard,
  elToFocus,
}) => {
  const total = [cashAndCheck, achCredit, achDebit, wire, creditCard].reduce(
    (t, n) => t + Number(n),
    0
  );
  const currentCost = totalFor(paymentFlow, {
    cashAndCheck,
    achCredit,
    achDebit,
    wire,
    creditCard,
  });

  return (
    <Row>
      <Col sm={12} md={6} lg={6} className={styles.inputCol}>
        <h2 className={styles.colHeading}># of Payments Per Month</h2>
        <Form className={styles.twoColForm}>
          <Form.Group controlId="cashAndCheck">
            <Form.Label>Checks / Cash</Form.Label>
            <Form.Control
              type="number"
              step="1"
              min="0"
              value={cashAndCheck}
              onChange={e => setCashAndCheck(e.target.value)}
              size="lg"
              ref={elToFocus}
            />
          </Form.Group>
          <Form.Group controlId="achCredit">
            <Form.Label>ACH Credit</Form.Label>
            <Form.Control
              type="number"
              step="1"
              min="0"
              value={achCredit}
              onChange={e => setAchCredit(e.target.value)}
              size="lg"
            />
          </Form.Group>
          <Form.Group controlId="achDebit">
            <Form.Label>ACH Debit</Form.Label>
            <Form.Control
              type="number"
              step="1"
              min="0"
              value={achDebit}
              onChange={e => setAchDebit(e.target.value)}
              size="lg"
            />
          </Form.Group>
          <Form.Group controlId="wire">
            <Form.Label>Wire</Form.Label>
            <Form.Control
              type="number"
              step="1"
              min="0"
              value={wire}
              onChange={e => setWire(e.target.value)}
              size="lg"
            />
          </Form.Group>
          <Form.Group controlId="creditCard">
            <Form.Label>Credit Card</Form.Label>
            <Form.Control
              type="number"
              step="1"
              min="0"
              value={creditCard}
              onChange={e => setCreditCard(e.target.value)}
              size="lg"
            />
          </Form.Group>
          <div className={styles.totalContainer}>
            <h3 className={styles.label}>Total # of Payments</h3>
            <div className={styles.total}>{total.toLocaleString()}</div>
          </div>
        </Form>
      </Col>
      <Col sm={12} md={6} lg={6} className={styles.costCol}>
        <h2 className={styles.colHeading}>Current Cost of Payments</h2>
        <div className={styles.currentCost}>
          <div>
            <span>$</span>
            {formatCurrencyWithoutDollarSign(currentCost)}
          </div>
        </div>
      </Col>
    </Row>
  );
};

AchCalculatorInput.propTypes = {
  paymentFlow: PropTypes.oneOf(['receivable, payable']).isRequired,
  cashAndCheck: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  setCashAndCheck: PropTypes.func.isRequired,
  achCredit: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  setAchCredit: PropTypes.func.isRequired,
  achDebit: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  setAchDebit: PropTypes.func.isRequired,
  wire: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setWire: PropTypes.func.isRequired,
  creditCard: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  setCreditCard: PropTypes.func.isRequired,
  elToFocus: PropTypes.element,
};

AchCalculatorInput.defaultProps = {
  elToFocus: null,
};

export default AchCalculatorInput;
