/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import DashboardView from './DashboardView';
import dashboardImage from '../../../assets/images/dashboard/graphic-dashboard-attach-bank-account@2x.png';

const AttachBankAccountDashboard = ({ customerId }) => {
  const dashboardProps = {
    ...(customerId != null && {
      link: `https://dashboard-sandbox.dwolla.com/customers/${customerId}/funding-sources`,
    }),
  };
  return <DashboardView image={dashboardImage} {...dashboardProps} />;
};

AttachBankAccountDashboard.propTypes = {
  customerId: PropTypes.string,
};

AttachBankAccountDashboard.defaultProps = {
  customerId: '',
};

export default AttachBankAccountDashboard;
