import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import PropTypes from 'prop-types';
import CalculatorPageNav from '../../molecules/page-nav/CalculatorPageNav';
import VideoLoopSelector from '../../molecules/video-loop-selector/VideoLoopSelector';
import { Heading, SubHeading } from '../../atoms/typography/Typography';
import CalculatorProgressBar from '../../molecules/progress-bar/CalculatorProgressBar';
import { scrollToTopOfIFrame } from '../../../utils/iframe';
import config from '../../../config';

import send from '../../../assets/images/funds-flows/Send.mp4';
import receive from '../../../assets/images/funds-flows/Receive.mp4';

const flowOptions = {
  payable: { label: 'Send', video: send },
  receivable: { label: 'Receive', video: receive },
};

const CalculatorFundsFlowsPage = ({
  fundsFlow,
  setFundsFlow,
  fundsFlowSetByUser,
}) => {
  useEffect(() => {
    fundsFlowSetByUser();
  }, [fundsFlowSetByUser]);

  useEffect(() => {
    scrollToTopOfIFrame();
  }, []);

  return (
    <div className="text-center">
      <CalculatorProgressBar currentStep={1} stepPercentage={0} />

      <Container className="container__center-content">
        <Heading>
          Find Out How Much Your Business Can Save With Electronic Payments
        </Heading>
        <SubHeading>
          Would you like to calculate costs based on the payments your business
          sends or receives?
        </SubHeading>

        <VideoLoopSelector
          videoOptions={flowOptions}
          selectedVideo={fundsFlow}
          setSelectedVideo={setFundsFlow}
        />
      </Container>

      <CalculatorPageNav nextLink={config.tourFlow.home.next} />
    </div>
  );
};

CalculatorFundsFlowsPage.propTypes = {
  fundsFlow: PropTypes.string.isRequired,
  setFundsFlow: PropTypes.func.isRequired,
  fundsFlowSetByUser: PropTypes.func.isRequired,
};

export default CalculatorFundsFlowsPage;
