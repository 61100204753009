import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ThemeButton from '../../../atoms/theme-button/ThemeButton';
import { themeOptions } from '../../../../utils/propTypeHelper';

const UnverifiedCustomerScreen = ({
  theme,
  isValidInput,
  pending,
  elToFocus,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  tosChecked,
  setTosChecked,
  handleSubmitClicked,
}) => (
  <Form>
    <Form.Group controlId="fn">
      <Form.Label>First Name:</Form.Label>
      <Form.Control
        type="input"
        value={firstName}
        onChange={e => setFirstName(e.target.value)}
        ref={elToFocus}
      />
    </Form.Group>
    <Form.Group controlId="ln">
      <Form.Label>Last Name:</Form.Label>
      <Form.Control
        type="input"
        value={lastName}
        onChange={e => setLastName(e.target.value)}
      />
    </Form.Group>
    <Form.Group controlId="email">
      <Form.Label>Email Address:</Form.Label>
      <Form.Control
        type="email"
        value={email}
        onChange={e => setEmail(e.target.value)}
      />
    </Form.Group>
    <Form.Group controlId="tos">
      <Form.Check
        type="checkbox"
        label="I agree to the Terms of Service."
        checked={tosChecked}
        onChange={e => setTosChecked(e.target.checked)}
      />
    </Form.Group>
    <ThemeButton
      theme={theme}
      disabled={!isValidInput}
      pending={pending}
      onClick={handleSubmitClicked}
    >
      Register
    </ThemeButton>
  </Form>
);

UnverifiedCustomerScreen.propTypes = {
  theme: PropTypes.oneOf(themeOptions).isRequired,
  isValidInput: PropTypes.bool,
  pending: PropTypes.bool,
  elToFocus: PropTypes.element,
  firstName: PropTypes.string,
  setFirstName: PropTypes.func,
  lastName: PropTypes.string,
  setLastName: PropTypes.func,
  email: PropTypes.string,
  setEmail: PropTypes.func,
  tosChecked: PropTypes.bool,
  setTosChecked: PropTypes.func,
  handleSubmitClicked: PropTypes.func,
};

UnverifiedCustomerScreen.defaultProps = {
  isValidInput: false,
  pending: false,
  elToFocus: null,
  firstName: '',
  setFirstName: null,
  lastName: '',
  setLastName: null,
  email: '',
  setEmail: null,
  tosChecked: false,
  setTosChecked: null,
  handleSubmitClicked: null,
};

export default UnverifiedCustomerScreen;
