/* eslint-disable react/forbid-prop-types,react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { themeOptions } from '../../../../utils/propTypeHelper';
import CheckMarkIcon from '../../../atoms/icons/CheckMarkIcon';
import styles from './BankAttachedTransferFundsScreen.module.scss';
import notificationStyles from '../notification-bar.module.scss';
import { Heading } from '../../../atoms/typography/Typography';
import Card from '../../../atoms/card/Card';
import { ReactComponent as WalletIcon } from '../../../../assets/images/phone-interface/icon-wallet.svg';
import { ReactComponent as BankIcon } from '../../../../assets/images/phone-interface/icon-bank.svg';
import formatCurrency from '../../../../utils/currency';
import ThemeButton from '../../../atoms/theme-button/ThemeButton';

const BalanceCard = ({ fs }) => (
  <Card>
    <WalletIcon className={styles.icon} />
    <h2 className={styles.fundingSource}>Available Balance:</h2>
    <p className={styles.balance}>{formatCurrency(fs.balance)}</p>
  </Card>
);

BalanceCard.propTypes = {
  fs: PropTypes.object.isRequired,
};

const BankCard = ({ theme, fs, transferLink }) => {
  const button = {
    ...(transferLink && {
      buttonText: `Transfer ${fs.status === 'verified' ? 'From' : 'To'}`,
      buttonLink: transferLink,
    }),
  };

  return (
    <Card theme={theme} {...button}>
      <BankIcon className={styles.icon} />
      <h2 className={styles.fundingSource}>{fs.name}</h2>
      <p className={classNames(styles.status, styles[fs.status])}>
        {fs.status}
      </p>
    </Card>
  );
};

BankCard.propTypes = {
  theme: PropTypes.oneOf(themeOptions).isRequired,
  fs: PropTypes.object.isRequired,
  transferLink: PropTypes.string.isRequired,
};

const FundingSourcesScreen = ({
  theme,
  successNotificationText,
  fundingSources,
  transferLink,
  handleAddBankClicked,
}) => (
  <>
    <div className={notificationStyles.notification}>
      <CheckMarkIcon theme={theme} style={notificationStyles.check} />
      <p>{successNotificationText}</p>
    </div>
    <div className={styles.container}>
      <Heading variant="theme" theme={theme} center>
        Funding Sources
      </Heading>
      {fundingSources.map(fs => {
        switch (fs.type) {
          case 'balance':
            return <BalanceCard fs={fs} />;
          case 'bank':
            return <BankCard transferLink={transferLink} fs={fs} />;
          default:
            return '';
        }
      })}
      {handleAddBankClicked && (
        <ThemeButton theme={theme} onClick={handleAddBankClicked}>
          Add a Bank
        </ThemeButton>
      )}
    </div>
  </>
);

FundingSourcesScreen.propTypes = {
  theme: PropTypes.oneOf(themeOptions).isRequired,
  successNotificationText: PropTypes.string.isRequired,
  fundingSources: PropTypes.arrayOf(PropTypes.object).isRequired,
  transferLink: PropTypes.string,
  handleAddBankClicked: PropTypes.func,
};

FundingSourcesScreen.defaultProps = {
  transferLink: null,
  handleAddBankClicked: null,
};

export default FundingSourcesScreen;
