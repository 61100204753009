import { connect } from 'react-redux';
import {
  setAreBubblesOpen,
  setBubbleSteps,
  closeBubbles,
} from '../../../redux/modules/tour-bubbles';
import TourBubbles from './TourBubbles';

const mapStateToProps = state => ({
  bubbleSteps: state.tourBubbles.bubbleSteps,
  viewedSteps: state.tourBubbles.viewedSteps,
});

const mapDispatchToProps = {
  setAreBubblesOpen,
  setBubbleSteps,
  closeBubbles,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TourBubbles);
