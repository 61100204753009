/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Code from './Code';

const http = ({ bearerToken }) =>
  `POST https://api-sandbox.dwolla.com/transfers HTTP/1.1
Accept: application/vnd.dwolla.v1.hal+json
Content-Type: application/vnd.dwolla.v1.hal+json
Authorization: Bearer ${bearerToken}`;

const json = ({ sourceUrl, destinationUrl, amount }) =>
  `{
  "_links": {
    "source": {
      "href": "${sourceUrl}"
    },
    "destination": {
      "href": "${destinationUrl}"
    }
  },
  "amount": {
    "currency": "USD",
    "value": "${amount}"
  }
}`;

const TransferRequestCode = props => (
  <Code http={http(props)} json={json(props)} />
);

TransferRequestCode.propTypes = {
  sourceUrl: PropTypes.string.isRequired,
  destinationUrl: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  bearerToken: PropTypes.string.isRequired,
};

export default TransferRequestCode;
