/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import CalculatorPageNav from '../../molecules/page-nav/CalculatorPageNav';
import { Heading } from '../../atoms/typography/Typography';
import CalculatorProgressBar from '../../molecules/progress-bar/CalculatorProgressBar';
import { scrollToTopOfIFrame } from '../../../utils/iframe';
import config from '../../../config';
import AchCalculatorInput from '../../molecules/ach-calculator-input/AchCalculatorInput';
import useFocusOnPageLoad from '../../../hooks/focus-on-page-load';

const CalculatorPaymentsPage = ({
  paymentFlow,
  cashAndCheck,
  setCashAndCheck,
  achCredit,
  setAchCredit,
  achDebit,
  setAchDebit,
  wire,
  setWire,
  creditCard,
  setCreditCard,
}) => {
  useEffect(() => {
    scrollToTopOfIFrame();
  }, []);

  const elToFocus = useRef(null);
  useFocusOnPageLoad(elToFocus);

  return (
    <div>
      <CalculatorProgressBar currentStep={2} stepPercentage={50} />

      <Container>
        <Heading center>Tell Us Your Monthly Payments Volume</Heading>

        <AchCalculatorInput
          {...{
            paymentFlow,
            cashAndCheck,
            setCashAndCheck,
            achCredit,
            setAchCredit,
            achDebit,
            setAchDebit,
            wire,
            setWire,
            creditCard,
            setCreditCard,
            elToFocus,
          }}
        />
      </Container>

      <CalculatorPageNav
        prevLink={config.tourFlow.calcPayments.back}
        nextLink={config.tourFlow.calcPayments.next}
      />
    </div>
  );
};

CalculatorPaymentsPage.propTypes = {
  paymentFlow: PropTypes.oneOf(['receivable, payable']).isRequired,
  cashAndCheck: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  setCashAndCheck: PropTypes.func.isRequired,
  achCredit: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  setAchCredit: PropTypes.func.isRequired,
  achDebit: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  setAchDebit: PropTypes.func.isRequired,
  wire: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setWire: PropTypes.func.isRequired,
  creditCard: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  setCreditCard: PropTypes.func.isRequired,
};

export default CalculatorPaymentsPage;
