import { combineReducers } from 'redux';
import general from './modules/general';
import createCustomer from './modules/create-customer';
import attachBank from './modules/attach-bank';
import moveMoney from './modules/move-money';
import fundingSources from './modules/funding-sources';
import tourBubbles from './modules/tour-bubbles';
import calculator from './modules/calculator';
import config from '../config';

const RESET_DATA = 'RESET_DATA';

export const resetData = () => ({
  type: RESET_DATA,
});

const appReducer = config.isCalculator
  ? combineReducers({
      calculator,
    })
  : combineReducers({
      general,
      createCustomer,
      attachBank,
      moveMoney,
      fundingSources,
      tourBubbles,
    });

const rootReducer = (state, action) => {
  if (action.type === RESET_DATA) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
