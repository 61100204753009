import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const HubSpotForm = ({ hubSpotFormId }) => {
  useEffect(() => {
    if (
      window &&
      window.hbspt &&
      window.hbspt.forms &&
      window.hbspt.forms.create
    ) {
      window.hbspt.forms.create({
        portalId: '7996980',
        formId: hubSpotFormId,
        target: `#hs-${hubSpotFormId}`,
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <div id={`hs-${hubSpotFormId}`} />;
};

HubSpotForm.propTypes = {
  hubSpotFormId: PropTypes.string.isRequired,
};

export default HubSpotForm;
