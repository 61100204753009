import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ThemeButton from '../../../atoms/theme-button/ThemeButton';
import { themeOptions } from '../../../../utils/propTypeHelper';
import styles from './CustomerCreatedScreen.module.scss';
import { Heading } from '../../../atoms/typography/Typography';
import CheckMarkIcon from '../../../atoms/icons/CheckMarkIcon';

const CustomerCreatedScreen = ({ theme, message, nextLink }) => (
  <div className={styles.container}>
    <div>
      <CheckMarkIcon theme={theme} style={styles.check} />
      <Heading variant="theme" theme={theme}>
        You&apos;re in!
      </Heading>
      <p className={styles.text}>{message}</p>
      <Link to={nextLink}>
        <ThemeButton theme={theme}>Attach a Bank</ThemeButton>
      </Link>
    </div>
  </div>
);

CustomerCreatedScreen.propTypes = {
  theme: PropTypes.oneOf(themeOptions).isRequired,
  message: PropTypes.string.isRequired,
  nextLink: PropTypes.string,
};

CustomerCreatedScreen.defaultProps = {
  nextLink: '',
};

export default CustomerCreatedScreen;
