import { connect } from 'react-redux';
import CalculatorPaymentsPage from './CalculatorPaymentsPage';
import {
  setCashAndCheck,
  setAchCredit,
  setAchDebit,
  setWire,
  setCreditCard,
} from '../../../redux/modules/calculator';

const mapStateToProps = state => ({
  paymentFlow: state.calculator.fundsFlow,
  cashAndCheck: state.calculator.payments.cashAndCheck,
  achCredit: state.calculator.payments.achCredit,
  achDebit: state.calculator.payments.achDebit,
  wire: state.calculator.payments.wire,
  creditCard: state.calculator.payments.creditCard,
});

const mapDispatchToProps = {
  setCashAndCheck,
  setAchCredit,
  setAchDebit,
  setWire,
  setCreditCard,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalculatorPaymentsPage);
