/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Code from './Code';

const http = ({ bearerToken }) =>
  `POST https://api-sandbox.dwolla.com/customers HTTP/1.1
Content-Type: application/vnd.dwolla.v1.hal+json
Accept: application/vnd.dwolla.v1.hal+json
Authorization: Bearer ${bearerToken}`;

const json = ({ firstName, lastName, email }) =>
  `{
  "firstName": "${firstName}",
  "lastName": "${lastName}",
  "email": "${email}",
  "ipAddress": "99.99.99.99"
}`;

const UnverifiedCustomerRequestCode = props => (
  <Code http={http(props)} json={json(props)} />
);

UnverifiedCustomerRequestCode.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  bearerToken: PropTypes.string.isRequired,
};

export default UnverifiedCustomerRequestCode;
