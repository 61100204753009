/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import useGenerateRandomBillAmount from '../../../../../hooks/generate-random-bill-amount';
import VerifiedBankAttachedPage from './VerifiedBankAttachedPage';

const VerifiedBankAttachedPayBillPageLoader = props => {
  const { generateRandomBillAmount, billAmount, ...remainingProps } = props;
  useGenerateRandomBillAmount(generateRandomBillAmount, billAmount);
  return (
    <VerifiedBankAttachedPage billAmount={billAmount} {...remainingProps} />
  );
};

VerifiedBankAttachedPayBillPageLoader.propTypes = {
  generateRandomBillAmount: PropTypes.func.isRequired,
  billAmount: PropTypes.number.isRequired,
};

export default VerifiedBankAttachedPayBillPageLoader;
