import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import styles from './Preloader.module.scss';

const Preloader = ({ show, context, dark }) => {
  if (!show) return false;

  return (
    <div
      className={classNames(styles.preloader, {
        [styles['preloader-button']]: context === 'button',
        [styles.dark]: dark,
      })}
    />
  );
};

Preloader.propTypes = {
  show: PropTypes.bool,
  context: PropTypes.string,
  dark: PropTypes.bool,
};

Preloader.defaultProps = {
  show: true,
  context: '',
  dark: false,
};

export default Preloader;
