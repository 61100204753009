import React from 'react';
import { LightAsync as SyntaxHighlighter } from 'react-syntax-highlighter';
import PropTypes from 'prop-types';
import customTheme from './code-highlighting';
import styles from './Code.module.scss';

const Code = ({ http, json }) => (
  <div className={styles.background}>
    <SyntaxHighlighter language="http" style={customTheme}>
      {http}
    </SyntaxHighlighter>
    {json && (
      <SyntaxHighlighter language="json" style={customTheme}>
        {json}
      </SyntaxHighlighter>
    )}
  </div>
);

Code.propTypes = {
  http: PropTypes.string.isRequired,
  json: PropTypes.string,
};

Code.defaultProps = {
  json: null,
};

export default Code;
