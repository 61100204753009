import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './BackgroundImage.module.scss';

const BackgroundImage = ({ src, alt, children, className }) => (
  <div
    className={classNames(styles.backgroundImageComponent, className)}
    style={{ backgroundImage: `url(${src})` }}
    aria-label={alt}
  >
    {children}
  </div>
);

BackgroundImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.node,
};

BackgroundImage.defaultProps = {
  alt: '',
  children: '',
  className: '',
};

export default BackgroundImage;
