import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { ReactComponent as CheckMarkSvg } from '../../../assets/images/phone-interface/icon-checkmark.svg';
import { themeOptions } from '../../../utils/propTypeHelper';
import styles from './CheckMarkIcon.module.scss';

const CheckMarkIcon = ({ theme, style }) => (
  <CheckMarkSvg
    className={classNames(styles[theme], {
      [style]: style,
    })}
  />
);

CheckMarkIcon.propTypes = {
  theme: PropTypes.oneOf(themeOptions).isRequired,
  style: PropTypes.string,
};

CheckMarkIcon.defaultProps = {
  style: null,
};

export default CheckMarkIcon;
