import React from 'react';
import PropTypes from 'prop-types';
import TransferCompletePageConnection from './TransferCompletePageConnection';

const SendToFriendCompletePage = ({ inviterFirstName }) => (
  <TransferCompletePageConnection text={`You Sent to ${inviterFirstName}`} />
);

SendToFriendCompletePage.propTypes = {
  inviterFirstName: PropTypes.string.isRequired,
};

export default SendToFriendCompletePage;
