import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PlaidLink from 'react-plaid-link';
import styles from './VerifyBankWithPlaidScreen.module.scss';
import { themeOptions } from '../../../../utils/propTypeHelper';
import { Heading } from '../../../atoms/typography/Typography';
import Preloader from '../../../atoms/preloader/Preloader';

const PLAID_LOADING_TIMEOUT = 1000;
const PLAID_OPEN_BUTTON_TIMEOUT = 1000;

const VerifyBankWithPlaidScreen = ({ theme, publicToken, handleSuccess }) => {
  const [loading, setLoading] = useState(true);
  const [plaidButtonVisible, setPlaidButtonVisible] = useState(false);

  const repositionPlaid = () => {
    if (loading) {
      const plaid = document.getElementsByTagName('iframe')[0];

      // The "plaid-link-iframe" class only appears on the mobile version of Plaid Link
      if (!plaid.classList.contains('plaid-link-iframe')) {
        const phoneScreen = document.getElementById('phoneScreen');
        const scaleFactor = phoneScreen.offsetWidth / 360;
        plaid.style.zIndex = 777;
        plaid.style.position = 'absolute';
        plaid.style.transformOrigin = 'top left';
        plaid.style.transform = `scale(${scaleFactor})`;
        plaid.style.width = '360px';
        plaid.style.height = '730px';
        phoneScreen.appendChild(plaid);
      }

      setTimeout(() => {
        setLoading(false);
        const plaidButtons = document.getElementsByClassName('plaidButton');
        [...plaidButtons].forEach(b => b.click());

        setTimeout(() => {
          setPlaidButtonVisible(true);
        }, PLAID_OPEN_BUTTON_TIMEOUT);
      }, PLAID_LOADING_TIMEOUT);
    }
  };

  const plaidEventHandler = eventName => {
    if (eventName === 'OPEN') {
      document.body.style.overflow = 'auto';
    }
  };

  return (
    <>
      <Heading variant="theme" theme={theme} center>
        Add and Verify Bank with Plaid
      </Heading>
      <div className={styles.container}>
        {(!!loading || !!publicToken) && <Preloader />}
        {!publicToken && (
          <div
            className={classNames({
              [styles.visuallyHidden]: !plaidButtonVisible,
            })}
          >
            <PlaidLink
              className={classNames(
                'btn',
                'btn-primary',
                `btn-${theme}`,
                'plaidButton'
              )}
              style={{}}
              clientName="Dwolla Demo App"
              env="sandbox"
              product={['auth']}
              publicKey="3feb85311fdc8d452a81e542ffe362"
              onSuccess={handleSuccess}
              onEvent={plaidEventHandler}
              onLoad={repositionPlaid}
            >
              Open Plaid
            </PlaidLink>
          </div>
        )}
      </div>
    </>
  );
};

VerifyBankWithPlaidScreen.propTypes = {
  theme: PropTypes.oneOf(themeOptions).isRequired,
  publicToken: PropTypes.string.isRequired,
  handleSuccess: PropTypes.func,
};

VerifyBankWithPlaidScreen.defaultProps = {
  handleSuccess: null,
};

export default VerifyBankWithPlaidScreen;
