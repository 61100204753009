import React from 'react';
import PropTypes from 'prop-types';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import styles from './HelpButton.module.scss';

const HelpButton = ({ bubbleSteps, setBubbleSteps }) => (
  <>
    {!!bubbleSteps.steps.length && (
      <OverlayTrigger placement="top" overlay={<Tooltip>Reopen Tour</Tooltip>}>
        <Button
          variant="outline-light"
          className={styles.button}
          onClick={() =>
            setBubbleSteps({
              ...bubbleSteps,
              name: 'help',
              isManuallyOpened: true,
            })
          }
          aria-label="Reopen Tour"
        >
          ?
        </Button>
      </OverlayTrigger>
    )}
  </>
);

HelpButton.propTypes = {
  bubbleSteps: PropTypes.shape({
    name: PropTypes.string,
    steps: PropTypes.array,
  }).isRequired,
  setBubbleSteps: PropTypes.func.isRequired,
};

export default HelpButton;
