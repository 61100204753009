import React from 'react';
import PropTypes from 'prop-types';
import PayoutsIcon from '../../../atoms/icons/PayoutsIcon';
import { themeOptions } from '../../../../utils/propTypeHelper';
import styles from './PayoutReceivedScreen.module.scss';
import { Heading } from '../../../atoms/typography/Typography';
import formatCurrency from '../../../../utils/currency';

const TransferCompleteScreen = ({ theme, text, amount }) => (
  <div className={styles.container}>
    <PayoutsIcon theme={theme} style={styles.icon} />
    <Heading preText={text} variant="theme" theme={theme} center>
      {formatCurrency(amount)}
    </Heading>
  </div>
);

TransferCompleteScreen.propTypes = {
  theme: PropTypes.oneOf(themeOptions).isRequired,
  text: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
};

export default TransferCompleteScreen;
