import { connect } from 'react-redux';
import { setBubbleSteps } from '../../../redux/modules/tour-bubbles';
import HelpButton from './HelpButton';

const mapStateToProps = state => ({
  bubbleSteps: state.tourBubbles.bubbleSteps,
});

const mapDispatchToProps = {
  setBubbleSteps,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HelpButton);
