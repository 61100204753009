export const SET_ARE_BUBBLES_OPEN = 'SET_ARE_BUBBLES_OPEN';
export const SET_BUBBLE_STEPS = 'SET_BUBBLE_STEPS';
export const PUSH_TO_VIEWED_STEPS = 'PUSH_TO_VIEWED_STEPS';

const initialState = {
  areBubblesOpen: false,
  bubbleSteps: {
    name: '',
    steps: [],
  },
  viewedSteps: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_ARE_BUBBLES_OPEN:
      return {
        ...state,
        areBubblesOpen: action.areBubblesOpen,
      };
    case SET_BUBBLE_STEPS:
      return {
        ...state,
        bubbleSteps: action.bubbleSteps,
      };
    case PUSH_TO_VIEWED_STEPS:
      return {
        ...state,
        viewedSteps: [...state.viewedSteps, action.viewedStep],
      };
    default:
      return state;
  }
}

export const setAreBubblesOpen = areBubblesOpen => ({
  type: SET_ARE_BUBBLES_OPEN,
  areBubblesOpen,
});

export const setBubbleSteps = bubbleSteps => ({
  type: SET_BUBBLE_STEPS,
  bubbleSteps,
});

export const pushToViewedSteps = viewedStep => ({
  type: PUSH_TO_VIEWED_STEPS,
  viewedStep,
});

export const closeBubbles = () => {
  return (dispatch, getState) => {
    const { bubbleSteps } = getState().tourBubbles;

    if (!bubbleSteps.isManuallyOpened) {
      dispatch(pushToViewedSteps(bubbleSteps.name));
    }

    dispatch(
      setBubbleSteps({
        name: '',
        isManuallyOpened: false,
        steps: bubbleSteps.steps,
      })
    );
  };
};
