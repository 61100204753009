import thunk from 'redux-thunk';
import { createStore as _createStore, applyMiddleware, compose } from 'redux';
import reducer from './reducer';
import callAPIMiddleware from './middleware/call-api-middleware';

export default function createStore() {
  const middleware = applyMiddleware(thunk, callAPIMiddleware());

  /* eslint-disable no-underscore-dangle */
  if (window.__REDUX_DEVTOOLS_EXTENSION__) {
    const create = compose(
      middleware,
      window.__REDUX_DEVTOOLS_EXTENSION__()
    )(_createStore);
    return create(reducer);
  }
  /* eslint-enable */

  return middleware(_createStore)(reducer);
}
