import {
  selfServiceFlow,
  boothFlow,
  calculatorFlow,
  getTestFlow,
} from './utils/flow-control/demo-flows';

const versions = {
  selfService: {
    versionName: 'selfService',
    forceOfflineMode: true,
    mobileGuardEnabled: true,
    tourBubblesEnabled: true,
    showCookieBar: true,
    tourFlow: selfServiceFlow,
  },

  booth: {
    versionName: 'booth',
    forceOfflineMode: false,
    mobileGuardEnabled: false,
    tourBubblesEnabled: false,
    showCookieBar: false,
    tourFlow: boothFlow,
  },

  calculator: {
    versionName: 'calculator',
    isCalculator: true,
    mobileGuardEnabled: false,
    tourBubblesEnabled: false,
    showCookieBar: false,
    tourFlow: calculatorFlow,
  },
};

const getTestVersion = () => ({
  ...versions.selfService,
  tourBubblesEnabled: false,
  tourFlow: getTestFlow(),
});

export default versions[process.env.REACT_APP_VERSION] || getTestVersion();
