import { connect } from 'react-redux';
import { resetData } from '../../../redux/reducer';
import { submitGetSavings } from '../../../redux/modules/calculator';
import CalculatorResultsPage from './CalculatorResultsPage';

const mapStateToProps = state => ({
  loading: state.calculator.loading,
  error: state.calculator.error,
  savings: state.calculator.savings,
});

const mapDispatchToProps = {
  resetData,
  submitGetSavings,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalculatorResultsPage);
