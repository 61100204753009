import React from 'react';
import PropTypes from 'prop-types';
import ProgressBar from './ProgressBar';

const CalculatorProgressBar = ({ currentStep, stepPercentage }) => (
  <ProgressBar
    steps={['Use Case Selection', 'Payments Volume', 'Your Results']}
    currentStep={currentStep}
    stepPercentage={stepPercentage}
    variant="gray"
  />
);

CalculatorProgressBar.propTypes = {
  currentStep: PropTypes.number,
  stepPercentage: PropTypes.number,
};

CalculatorProgressBar.defaultProps = {
  currentStep: 0,
  stepPercentage: 0,
};

export default CalculatorProgressBar;
