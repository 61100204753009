import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ThemeButton from '../../../atoms/theme-button/ThemeButton';
import { themeOptions } from '../../../../utils/propTypeHelper';
import { Heading } from '../../../atoms/typography/Typography';

const VerifiedCustomerScreen = ({
  theme,
  isValidInput,
  pending,
  elToFocus,
  heading,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  address1,
  setAddress1,
  city,
  setCity,
  state,
  setState,
  postalCode,
  setPostalCode,
  dob,
  setDob,
  ssnLastFour,
  setSsnLastFour,
  tosChecked,
  setTosChecked,
  handleSubmitClicked,
}) => (
  <>
    {heading && (
      <Heading variant="theme" theme={theme}>
        {heading}
      </Heading>
    )}
    <Form>
      <Form.Group controlId="fn">
        <Form.Label>First Name:</Form.Label>
        <Form.Control
          type="input"
          value={firstName}
          onChange={e => setFirstName(e.target.value)}
          ref={elToFocus}
        />
      </Form.Group>
      <Form.Group controlId="ln">
        <Form.Label>Last Name:</Form.Label>
        <Form.Control
          type="input"
          value={lastName}
          onChange={e => setLastName(e.target.value)}
        />
      </Form.Group>
      <Form.Group controlId="email">
        <Form.Label>Email Address:</Form.Label>
        <Form.Control
          type="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
      </Form.Group>
      <Form.Group controlId="dob">
        <Form.Label>Date of Birth:</Form.Label>
        <Form.Control
          type="date"
          value={dob}
          onChange={e => setDob(e.target.value)}
        />
      </Form.Group>
      <Form.Group controlId="ssn">
        <Form.Label>Last Four of SSN:</Form.Label>
        <Form.Control
          type="input"
          value={ssnLastFour}
          maxLength="4"
          onChange={e => setSsnLastFour(e.target.value)}
        />
      </Form.Group>
      <Form.Group controlId="address">
        <Form.Label>Address Line 1:</Form.Label>
        <Form.Control
          type="input"
          value={address1}
          onChange={e => setAddress1(e.target.value)}
        />
      </Form.Group>
      <Form.Group controlId="city">
        <Form.Label>City:</Form.Label>
        <Form.Control
          type="input"
          value={city}
          onChange={e => setCity(e.target.value)}
        />
      </Form.Group>
      <Form.Group controlId="state">
        <Form.Label>State:</Form.Label>
        <Form.Control
          type="input"
          maxLength="2"
          value={state}
          onChange={e => setState(e.target.value)}
        />
      </Form.Group>
      <Form.Group controlId="zip">
        <Form.Label>ZIP:</Form.Label>
        <Form.Control
          type="input"
          maxLength="5"
          value={postalCode}
          onChange={e => setPostalCode(e.target.value)}
        />
      </Form.Group>
      <Form.Group controlId="tos">
        <Form.Check
          type="checkbox"
          label="I agree to the Terms of Service."
          checked={tosChecked}
          onChange={e => setTosChecked(e.target.checked)}
        />
      </Form.Group>
      <ThemeButton
        theme={theme}
        disabled={!isValidInput}
        pending={pending}
        onClick={handleSubmitClicked}
      >
        Register
      </ThemeButton>
    </Form>
  </>
);

VerifiedCustomerScreen.propTypes = {
  theme: PropTypes.oneOf(themeOptions).isRequired,
  isValidInput: PropTypes.bool,
  pending: PropTypes.bool,
  elToFocus: PropTypes.element,
  heading: PropTypes.string,
  firstName: PropTypes.string,
  setFirstName: PropTypes.func,
  lastName: PropTypes.string,
  setLastName: PropTypes.func,
  email: PropTypes.string,
  setEmail: PropTypes.func,
  address1: PropTypes.string,
  setAddress1: PropTypes.func,
  city: PropTypes.string,
  setCity: PropTypes.func,
  state: PropTypes.string,
  setState: PropTypes.func,
  postalCode: PropTypes.string,
  setPostalCode: PropTypes.func,
  dob: PropTypes.string,
  setDob: PropTypes.func,
  ssnLastFour: PropTypes.string,
  setSsnLastFour: PropTypes.func,
  tosChecked: PropTypes.bool,
  setTosChecked: PropTypes.func,
  handleSubmitClicked: PropTypes.func,
};

VerifiedCustomerScreen.defaultProps = {
  isValidInput: false,
  pending: false,
  elToFocus: null,
  heading: '',
  firstName: '',
  setFirstName: null,
  lastName: '',
  setLastName: null,
  email: '',
  setEmail: null,
  address1: '',
  setAddress1: null,
  city: '',
  setCity: null,
  state: '',
  setState: null,
  postalCode: '',
  setPostalCode: null,
  dob: '',
  setDob: null,
  ssnLastFour: '',
  setSsnLastFour: null,
  tosChecked: false,
  setTosChecked: null,
  handleSubmitClicked: null,
};

export default VerifiedCustomerScreen;
