/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import CreateVerifiedCustomerPage from './CreateVerifiedCustomerPage';

const CreateVerifiedCustomerSendToFriendPageLoader = props => {
  const { submitLoadFacilitatorCustomer, ...remainingProps } = props;

  useEffect(() => {
    submitLoadFacilitatorCustomer();
  }, [submitLoadFacilitatorCustomer]);

  return <CreateVerifiedCustomerPage {...remainingProps} />;
};

CreateVerifiedCustomerSendToFriendPageLoader.propTypes = {
  submitLoadFacilitatorCustomer: PropTypes.func.isRequired,
};

export default CreateVerifiedCustomerSendToFriendPageLoader;
