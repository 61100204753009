/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import SendMoneyPage from './SendMoneyPage';

const PayBillPageLoader = props => {
  const { submitLoadAccount, ...remainingProps } = props;

  useEffect(() => {
    submitLoadAccount();
  }, [submitLoadAccount]);

  return <SendMoneyPage {...remainingProps} />;
};

PayBillPageLoader.propTypes = {
  submitLoadAccount: PropTypes.func.isRequired,
};

export default PayBillPageLoader;
