import { useEffect } from 'react';

const useGenerateRandomBillAmount = (
  generateRandomBillAmount,
  currentBillAmount
) => {
  useEffect(() => {
    if (!currentBillAmount) {
      generateRandomBillAmount();
    }
  }, [generateRandomBillAmount, currentBillAmount]);
};

export default useGenerateRandomBillAmount;
