import React from 'react';
import PropTypes from 'prop-types';
import styles from './VideoLoopSelector.module.scss';
import VideoLoopSelectionButtons from '../../atoms/video-loop/VideoLoopSelectionButtons';
import VideoLoopWrap from '../../atoms/video-loop/VideoLoopWrap';

const VideoLoopSelector = ({
  videoOptions,
  selectedVideo,
  setSelectedVideo,
}) => {
  return (
    <>
      <VideoLoopSelectionButtons
        videoOptions={videoOptions}
        selectedVideo={selectedVideo}
        setSelectedVideo={setSelectedVideo}
      />

      <div className={styles.videoLoopWrap}>
        <VideoLoopWrap
          videoOptions={videoOptions}
          selectedVideo={selectedVideo}
        />
      </div>
    </>
  );
};

VideoLoopSelector.propTypes = {
  videoOptions: PropTypes.objectOf(PropTypes.objectOf(PropTypes.string))
    .isRequired,
  selectedVideo: PropTypes.string.isRequired,
  setSelectedVideo: PropTypes.func.isRequired,
};

export default VideoLoopSelector;
