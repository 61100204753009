import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { formatCurrencyWithoutDollarSign } from '../../../utils/currency';
import styles from './AchCalculatorResults.module.scss';
import Preloader from '../../atoms/preloader/Preloader';
import { SubHeading } from '../../atoms/typography/Typography';

const AchCalculatorResults = ({ loading, error, savings, tryAgain }) => {
  return (
    <>
      <SubHeading>
        Based on your current payment mix, you have an opportunity to save up
        to:
      </SubHeading>
      <div className={styles.savingsContainer}>
        {!!loading && (
          <div className={styles.preloaderWrapper}>
            <Preloader dark />
          </div>
        )}
        {!loading && !error && (
          <p className={styles.savings}>
            <span>$</span>
            {formatCurrencyWithoutDollarSign(savings)}
          </p>
        )}
        {!error && <p>on a monthly basis by switching to ACH*</p>}
        {!loading && !!error && (
          <>
            <p>
              We couldn&apos;t retrieve your potential savings at this time.
            </p>
            <Button variant="dwolla" onClick={tryAgain}>
              Try Again
            </Button>
          </>
        )}
      </div>
      {!error && (
        <p className={styles.disclaimer}>
          *Note: These results do not include any payments associated
          integration costs or fees paid to an ACH API like Dwolla.
        </p>
      )}
    </>
  );
};

AchCalculatorResults.propTypes = {
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  savings: PropTypes.number.isRequired,
  tryAgain: PropTypes.func.isRequired,
};

export default AchCalculatorResults;
