/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import classNames from 'classnames';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import styles from './ThemeButton.module.scss';
import { themeOptions } from '../../../utils/propTypeHelper';
import Preloader from '../preloader/Preloader';

const ThemeButton = props => {
  const { children, theme, disabled, pending, ...restOfProps } = props;

  return (
    <Button
      variant="primary"
      type="submit"
      className={classNames(styles.button, `btn-${theme}`, {
        [styles.pending]: pending,
      })}
      disabled={pending || disabled}
      {...restOfProps}
    >
      <span>{children}</span>
      {pending && <Preloader context="button" />}
    </Button>
  );
};

ThemeButton.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.oneOf(themeOptions).isRequired,
  disabled: PropTypes.bool,
  pending: PropTypes.bool,
};

ThemeButton.defaultProps = {
  disabled: false,
  pending: false,
};

export default ThemeButton;
