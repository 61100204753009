const objectMap = (obj, transformFunc) => {
  const transformed = {};

  Object.keys(obj).forEach(k => {
    transformed[k] = transformFunc({ k, v: obj[k] });
  });

  return transformed;
};

export default objectMap;
