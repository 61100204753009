import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Tour from 'reactour';
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock';
import styles from './TourBubbles.module.scss';

const TourContent = ({ heading, children }) => (
  <>
    {heading && <h2 className={styles.heading}>{heading}</h2>}
    {children && <div className={styles.content}>{children}</div>}
  </>
);

TourContent.propTypes = {
  heading: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

TourContent.defaultProps = {
  heading: '',
  children: '',
};

const TourBubbles = ({
  bubbleSteps,
  viewedSteps,
  setAreBubblesOpen,
  setBubbleSteps,
  closeBubbles,
}) => {
  useEffect(() => {
    return function cleanup() {
      clearAllBodyScrollLocks();
      setBubbleSteps({
        name: '',
        steps: [],
      });
    };
  }, [setBubbleSteps]);

  const steps = bubbleSteps.steps.map(step => ({
    ...step,
    content: () => (
      <TourContent heading={step.heading}>{step.content}</TourContent>
    ),
  }));

  return (
    <Tour
      className={styles.bubble}
      steps={steps}
      isOpen={!!(bubbleSteps.name && !viewedSteps.includes(bubbleSteps.name))}
      onRequestClose={closeBubbles}
      maskSpace={13}
      maskClassName={styles.mask}
      highlightedMaskClassName={styles.maskHighlight}
      accentColor="#5e4e77"
      onAfterOpen={() => {
        disableBodyScroll();
        setAreBubblesOpen(true);
      }}
      onBeforeClose={() => {
        enableBodyScroll();
        setAreBubblesOpen(false);
      }}
    />
  );
};

TourBubbles.propTypes = {
  bubbleSteps: PropTypes.shape({
    name: PropTypes.string,
    steps: PropTypes.array,
  }).isRequired,
  viewedSteps: PropTypes.arrayOf(PropTypes.string).isRequired,
  setAreBubblesOpen: PropTypes.func.isRequired,
  setBubbleSteps: PropTypes.func.isRequired,
  closeBubbles: PropTypes.func.isRequired,
};

export default TourBubbles;
