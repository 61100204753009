import React from 'react';
import PropTypes from 'prop-types';
import Code from './Code';

const request = resourceUrl =>
  `HTTP/1.1 201 Created
Location: ${resourceUrl}`;

const CreatedResponseCode = ({ resourceUrl }) => (
  <Code http={request(resourceUrl)} />
);

CreatedResponseCode.propTypes = {
  resourceUrl: PropTypes.string.isRequired,
};

export default CreatedResponseCode;
