const fundingSourceMapper = fundingSources => {
  return fundingSources.map(fs => ({
    name: fs.name,
    value: fs._links.self.href,
    type: fs.type,
    status: fs.status,
    ...(fs.balance && { balance: fs.balance }),
  }));
};

export const verifiedFundingSourceMapper = fundingSources =>
  fundingSourceMapper(fundingSources).filter(fs => fs.status === 'verified');

export const filterFundingSources = (fundingSources, filteredValues = []) =>
  fundingSources.filter(
    fs => fs.name !== 'Balance' && !filteredValues.includes(fs.value)
  );

export default fundingSourceMapper;
