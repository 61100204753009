import objectMap from './object-mapper';

export const ensureNumeric = obj => objectMap(obj, ({ v }) => Number(v));

const roundToPrecision = (x, precision) => {
  const y = +x + (precision === undefined ? 0.5 : precision / 2);
  return y - (y % (precision === undefined ? 1 : +precision));
};

export const roundCurrency = x => roundToPrecision(x, 0.01);

export const roundUpOrClearOut = obj => {
  return objectMap(obj, ({ v }) => {
    // eslint-disable-next-line no-restricted-globals
    return isNaN(v) || v < 1 ? '' : Math.ceil(v);
  });
};
