import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Container } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import CalculatorPageNav from '../../molecules/page-nav/CalculatorPageNav';
import { Heading } from '../../atoms/typography/Typography';
import CalculatorProgressBar from '../../molecules/progress-bar/CalculatorProgressBar';
import { ReactComponent as HomeIcon } from '../../../assets/images/icons/icon-home.svg';
import { scrollToTopOfIFrame } from '../../../utils/iframe';
import config from '../../../config';
import AchCalculatorResults from '../../molecules/ach-calculator-results/AchCalculatorResults';

const CalculatorResultsPage = ({
  loading,
  error,
  savings,
  resetData,
  submitGetSavings,
}) => {
  const [redirectToHome, setRedirectToHome] = useState(false);

  useEffect(() => {
    submitGetSavings();
  }, [submitGetSavings]);

  useEffect(() => {
    scrollToTopOfIFrame();
  }, []);

  const startOverHandler = () => {
    resetData();
    setRedirectToHome(true);
  };

  return (
    <div className="text-center">
      {redirectToHome ? <Redirect to="/" /> : null}

      <CalculatorProgressBar currentStep={3} stepPercentage={100} />

      <Container>
        <Heading center>Your Potential Monthly Savings</Heading>
        <AchCalculatorResults
          loading={loading}
          error={error}
          savings={savings}
          tryAgain={submitGetSavings}
        />
        <Heading center>
          Combine efficient ACH payments with Dwolla&apos;s excellent technology
        </Heading>
        <a
          href="https://www.dwolla.com/contact"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button variant="dwolla">Let&apos;s Talk</Button>
        </a>
      </Container>

      <CalculatorPageNav
        prevLink={config.tourFlow.calcResults.back}
        nextText="Start Over"
        nextOnClick={startOverHandler}
        NextIcon={HomeIcon}
      />
    </div>
  );
};

CalculatorResultsPage.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.bool,
  savings: PropTypes.number,
  resetData: PropTypes.func.isRequired,
  submitGetSavings: PropTypes.func.isRequired,
};

CalculatorResultsPage.defaultProps = {
  loading: false,
  error: false,
  savings: 0,
};

export default CalculatorResultsPage;
