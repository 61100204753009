import { ensureNumeric, roundCurrency } from './numbers';

const feeSchedule = {
  cashAndCheck: {
    receivable: 1.57,
    payable: 3,
  },
  achCredit: {
    receivable: 0.5,
    payable: 0.5,
  },
  achDebit: {
    receivable: 0.25,
    payable: 0.25,
  },
  wire: {
    receivable: 7.64,
    payable: 8,
  },
  creditCard: {
    receivable: 4,
    payable: 0,
  },
};

const totalFor = (flow, payments) => {
  return roundCurrency(
    Object.keys(payments).reduce(
      (total, k) => total + payments[k] * feeSchedule[k][flow],
      0
    )
  );
};

export default (flow, payments) => totalFor(flow, ensureNumeric(payments));
