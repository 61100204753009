import { connect } from 'react-redux';
import {
  setFundsFlow as setFundsFlowAction,
  fundsFlowSetByUser as fundsFlowSetByUserAction,
} from '../../../redux/modules/general';
import { resetData as resetDataAction } from '../../../redux/reducer';
import getPageVariation from '../../../utils/page-variations';
import config from '../../../config';

const mapStateToProps = state => ({
  fundsFlow: state.general.fundsFlow,
});

const mapDispatchToProps = {
  setFundsFlow: setFundsFlowAction,
  resetData: resetDataAction,
  fundsFlowSetByUser: fundsFlowSetByUserAction,
};

const mergeProps = (stateProps, dispatchProps) => {
  const { setFundsFlow, resetData, fundsFlowSetByUser } = dispatchProps;

  return {
    ...stateProps,
    setFundsFlow: selectedFundsFlow => {
      resetData();
      fundsFlowSetByUser();
      setFundsFlow(selectedFundsFlow);
    },
    fundsFlowSetByUser,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(getPageVariation('fundsFlows', config.versionName));
