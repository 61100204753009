import { connect } from 'react-redux';
import { setTheme } from '../../../redux/modules/general';
import getPageVariation from '../../../utils/page-variations';
import config from '../../../config';

const mapStateToProps = state => ({
  theme: state.general.theme,
});

const mapDispatchToProps = {
  setTheme,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(getPageVariation('appBuilder', config.versionName));
