const pink = '#B45EA4';
const blue = '#4FB4D7';
const orange = '#EE7C2B';
const green = '#78BB65';

// modified from theme called "an old hope"
export default {
  'hljs-comment': {
    color: '#B6B18B',
  },
  'hljs-quote': {
    color: '#B6B18B',
  },
  'hljs-variable': {
    color: '#EB3C54',
  },
  'hljs-template-variable': {
    color: '#EB3C54',
  },
  'hljs-tag': {
    color: '#EB3C54',
  },
  'hljs-name': {
    color: '#EB3C54',
  },
  'hljs-selector-id': {
    color: '#EB3C54',
  },
  'hljs-selector-class': {
    color: '#EB3C54',
  },
  'hljs-regexp': {
    color: '#EB3C54',
  },
  'hljs-deletion': {
    color: '#EB3C54',
  },
  'hljs-number': {
    color: green,
  },
  'hljs-built_in': {
    color: '#E7CE56',
  },
  'hljs-builtin-name': {
    color: '#E7CE56',
  },
  'hljs-literal': {
    color: '#E7CE56',
  },
  'hljs-type': {
    color: '#E7CE56',
  },
  'hljs-params': {
    color: '#E7CE56',
  },
  'hljs-meta': {
    color: '#E7CE56',
  },
  'hljs-link': {
    color: '#E7CE56',
  },
  'hljs-attribute': {
    color: blue,
  },
  'hljs-string': {
    color: orange,
  },
  'hljs-symbol': {
    color: '#4FB4D7',
  },
  'hljs-bullet': {
    color: '#4FB4D7',
  },
  'hljs-addition': {
    color: '#4FB4D7',
  },
  'hljs-title': {
    color: '#78BB65',
  },
  'hljs-section': {
    color: '#78BB65',
  },
  'hljs-keyword': {
    color: pink,
  },
  'hljs-selector-tag': {
    color: '#B45EA4',
  },
  hljs: {
    display: 'table',
    background: 'none',
    color: '#c0c5ce',
    padding: '0.5em',
  },
  'hljs-emphasis': {
    fontStyle: 'italic',
  },
  'hljs-strong': {
    fontWeight: 'bold',
  },
};
