import React from 'react';
import PropTypes from 'prop-types';
import styles from './NoPayoutsIcon.module.scss';
import { ReactComponent as NoPayoutsSvg } from '../../../assets/images/phone-interface/icon-no-payouts.svg';
import { themeOptions } from '../../../utils/propTypeHelper';

const NoPayoutsIcon = ({ theme }) => <NoPayoutsSvg className={styles[theme]} />;

NoPayoutsIcon.propTypes = {
  theme: PropTypes.oneOf(themeOptions).isRequired,
};

export default NoPayoutsIcon;
