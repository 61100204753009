/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Code from './Code';

const http = ({ customerUrl, bearerToken }) =>
  `POST ${customerUrl}/funding-sources HTTP/1.1
Content-Type: application/vnd.dwolla.v1.hal+json
Accept: application/vnd.dwolla.v1.hal+json
Authorization: Bearer ${bearerToken}`;

const json = () =>
  `{

}`;

const EmptyBankRequestCode = props => <Code http={http(props)} json={json()} />;

EmptyBankRequestCode.propTypes = {
  customerUrl: PropTypes.string.isRequired,
  bearerToken: PropTypes.string.isRequired,
};

export default EmptyBankRequestCode;
