/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import classNames from 'classnames';
import PageNav from './PageNav';
import { ReactComponent as NachaFullLogo } from '../../../assets/images/partners/logo-nacha-full.svg';
import { ReactComponent as NachaMarkLogo } from '../../../assets/images/partners/logo-nacha-mark.svg';
import styles from './CalculatorPageNav.module.scss';

const PoweredByNachaLogo = () => (
  <a
    href="https://www.nacha.org/"
    target="_blank"
    rel="noopener noreferrer"
    className={styles.logoWrap}
  >
    <span className={styles.text}>
      Powered By
      <div className={styles.mobileOnly}>Nacha</div>
    </span>
    <span className={classNames(styles.logo, styles.fullLogo)}>
      <NachaFullLogo />
    </span>
    <span className={classNames(styles.logo, styles.markLogo)}>
      <NachaMarkLogo />
    </span>
  </a>
);

const CalculatorPageNav = ({ ...props }) => (
  <PageNav {...props} bg="white" Logo={PoweredByNachaLogo} />
);

export default CalculatorPageNav;
