/* eslint-disable no-plusplus,no-loop-func */
import Chance from 'chance';

const DELAY = 20;

export const chance = new Chance();
chance.mixin({
  comEmail: () => chance.email({ domain: `${chance.word()}.com` }),
  bankAccountName: accountType =>
    `${chance.animal({ type: 'grassland' })} ${chance.pickone([
      'Bank',
      'CU',
      'Central',
      'National',
      'Financial',
    ])} ${accountType}`,
});

export const slowFillSingle = (val, setFunc) => {
  return new Promise(resolve => {
    setTimeout(() => {
      setFunc(val);
      resolve(val);
    }, DELAY);
  });
};

export const slowFill = (val, setFunc) => {
  return new Promise(resolve => {
    const lastChar = val.length - 1;
    const arr = [...val];
    let fill = '';

    for (let i = 0; i < val.length; i++) {
      setTimeout(() => {
        fill += arr[i];
        setFunc(fill);
        if (i === lastChar) {
          resolve();
        }
      }, i * DELAY);
    }
  });
};
