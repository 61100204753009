import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import classNames from 'classnames';
import styles from './DashboardView.module.scss';

const DashboardView = ({ image, link }) => {
  return (
    <div className={styles.dashboardViewComponent}>
      <div className={styles.content}>
        <img
          src={image}
          alt="Mock Dwolla Dashboard View"
          className={styles.image}
        />
        {link && (
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            <Button
              variant="primary"
              className={classNames('btn-dwolla', styles.button)}
            >
              View in Dashboard
            </Button>
          </a>
        )}
      </div>
    </div>
  );
};

DashboardView.propTypes = {
  image: PropTypes.string.isRequired,
  link: PropTypes.string,
};

DashboardView.defaultProps = {
  link: '',
};

export default DashboardView;
