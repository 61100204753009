import { connect } from 'react-redux';
import AttachBankMapper from './AttachBankMapper';

const mapStateToProps = state => ({
  fundsFlow: state.general.fundsFlow,
  variation: state.attachBank.variation,
  subStep: state.attachBank.subStep,
});

export default connect(mapStateToProps)(AttachBankMapper);
