import config from '../../config';

function validate(types, callAPI, dispatchOnSuccess) {
  if (
    !Array.isArray(types) ||
    types.length !== 3 ||
    !types.every(type => typeof type === 'string')
  ) {
    throw new Error('Expected an array of three string types.');
  }

  if (typeof callAPI !== 'function') {
    throw new Error('Expected callAPI to be a function.');
  }

  if (
    dispatchOnSuccess &&
    (!Array.isArray(dispatchOnSuccess) ||
      dispatchOnSuccess.length < 1 ||
      !dispatchOnSuccess.every(f => typeof f === 'function'))
  ) {
    throw new Error('Expected an array of functions to dispatch on success.');
  }
}

export default function callAPIMiddleware() {
  return ({ getState, dispatch }) => next => action => {
    const {
      types,
      callAPI,
      payload = {},
      meta,
      dispatchOnSuccess,
      dispatchOnSuccessDelay,
    } = action;

    if (!types) {
      return next(action);
    }

    validate(types, callAPI, dispatchOnSuccess);

    const [requestType, successType, failureType] = types;

    dispatch({
      ...payload,
      type: requestType,
    });

    const delayedDispatch = a => {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve(dispatch(a));
        }, dispatchOnSuccessDelay);
      });
    };

    const onSuccess = response => {
      const promises = [];

      if (dispatchOnSuccess) {
        dispatchOnSuccess.forEach(actionCreator => {
          const result = actionCreator(response);
          if (result) {
            promises.push(
              dispatchOnSuccessDelay
                ? delayedDispatch(result)
                : dispatch(result)
            );
          }
        });
      }

      promises.push(
        dispatch({
          ...payload,
          meta,
          body: response,
          type: successType,
        })
      );

      return Promise.all(promises);
    };

    const dispatchFailure = (status, response) =>
      dispatch({
        ...payload,
        error: {
          status,
          response,
        },
        type: failureType,
      });

    const onError = error =>
      error
        .json()
        .then(
          json => dispatchFailure(error.status, json),
          () => dispatchFailure(error.status, {})
        )
        .then(() => Promise.reject(error));

    if (!config.isCalculator && getState().general.offlineMode) {
      return new Promise(resolve =>
        setTimeout(() => resolve(dispatchFailure('offline', {})), 1000)
      );
    }

    return callAPI().then(onSuccess, onError);
  };
}
