const apiRootUrls = {
  local: '', // uses Webpack proxy
  devint: 'https://demo-api-devint.dwolla.com',
  sandbox: 'https://demo-api.dwolla.com',
};

const root = apiRootUrls.sandbox;

export const postJson = (path, data) => {
  return fetch(`${root}${path}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(res => {
    if (!res.ok) throw res;
    return res.json();
  });
};

export const get = path => {
  return fetch(`${root}${path}`).then(res => {
    if (!res.ok) throw res;
    return res.json();
  });
};
