import { roundUpOrClearOut } from '../../utils/numbers';
import { postJson } from '../../utils/api';

export const SET_FUNDS_FLOW = 'SET_FUNDS_FLOW';
export const FUNDS_FLOW_SET_BY_USER = 'FUNDS_FLOW_SET_BY_USER';
export const SET_NUMBER_OF_PAYMENTS = 'SET_NUMBER_OF_PAYMENTS';

export const SUBMIT_GET_SAVINGS = 'SUBMIT_GET_SAVINGS';
export const GET_SAVINGS_SUCCESS = 'GET_SAVINGS_SUCCESS';
export const GET_SAVINGS_FAILURE = 'GET_SAVINGS_FAILURE';

const initialState = {
  loading: true,
  fundsFlow: 'payable',
  isFundsFlowSetByUser: false,
  payments: {
    cashAndCheck: '',
    achCredit: '',
    achDebit: '',
    wire: '',
    creditCard: '',
  },
  savings: 0,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_FUNDS_FLOW:
      return {
        ...state,
        fundsFlow: action.flow,
      };
    case FUNDS_FLOW_SET_BY_USER:
      return {
        ...state,
        isFundsFlowSetByUser: true,
      };
    case SET_NUMBER_OF_PAYMENTS:
      return {
        ...state,
        payments: {
          ...state.payments,
          ...action.payments,
        },
      };
    case SUBMIT_GET_SAVINGS:
      return {
        ...state,
        loading: true,
      };
    case GET_SAVINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        savings: action.body.amount,
      };
    case GET_SAVINGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
}

export const setFundsFlow = flow => ({
  type: SET_FUNDS_FLOW,
  flow,
});

export const fundsFlowSetByUser = () => ({
  type: FUNDS_FLOW_SET_BY_USER,
});

export const setNumberOfPayments = payments => ({
  type: SET_NUMBER_OF_PAYMENTS,
  payments: roundUpOrClearOut(payments),
});

export const setCashAndCheck = cashAndCheck =>
  setNumberOfPayments({ cashAndCheck });
export const setAchCredit = achCredit => setNumberOfPayments({ achCredit });
export const setAchDebit = achDebit => setNumberOfPayments({ achDebit });
export const setWire = wire => setNumberOfPayments({ wire });
export const setCreditCard = creditCard => setNumberOfPayments({ creditCard });

export const submitGetSavings = () => {
  return (dispatch, getState) => {
    const { fundsFlow, payments } = getState().calculator;
    const { cashAndCheck, achCredit, achDebit, wire, creditCard } = payments;
    dispatch({
      types: [SUBMIT_GET_SAVINGS, GET_SAVINGS_SUCCESS, GET_SAVINGS_FAILURE],
      callAPI: () =>
        postJson('/calc', {
          flow: fundsFlow,
          payments: {
            cashAndCheck,
            achCredit,
            achDebit,
            wire,
            creditCard,
          },
        }),
    });
  };
};
