import { connect } from 'react-redux';
import {
  setFundsFlow as setFundsFlowAction,
  fundsFlowSetByUser as fundsFlowSetByUserAction,
} from '../../../redux/modules/calculator';
import CalculatorFundsFlowsPage from './CalculatorFundsFlowsPage';

const mapStateToProps = state => ({
  fundsFlow: state.calculator.fundsFlow,
});

const mapDispatchToProps = {
  setFundsFlow: setFundsFlowAction,
  fundsFlowSetByUser: fundsFlowSetByUserAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalculatorFundsFlowsPage);
