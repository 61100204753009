/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import useGenerateRandomBillAmount from '../../../../../hooks/generate-random-bill-amount';
import BankVerifiedPage from './BankVerifiedPage';

const BankVerifiedPayBillPageLoader = props => {
  const { generateRandomBillAmount, billAmount, ...remainingProps } = props;
  useGenerateRandomBillAmount(generateRandomBillAmount, billAmount);
  return <BankVerifiedPage billAmount={billAmount} {...remainingProps} />;
};

BankVerifiedPayBillPageLoader.propTypes = {
  generateRandomBillAmount: PropTypes.func.isRequired,
  billAmount: PropTypes.number.isRequired,
};

export default BankVerifiedPayBillPageLoader;
