import { useEffect } from 'react';

const useFocusOnPageLoad = inputEl => {
  useEffect(() => {
    inputEl.current.focus();
  }, [inputEl]);
};

export const useFocusOnPageLoadWithLoadingAnimation = (inputEl, loading) => {
  useEffect(() => {
    if (!loading) {
      inputEl.current.focus();
    }
  }, [inputEl, loading]);
};

export default useFocusOnPageLoad;
