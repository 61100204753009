import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

const RefreshRoute = ({
  component: Component,
  isFundsFlowSetByUser,
  redirectPath,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      isFundsFlowSetByUser ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: redirectPath,
          }}
        />
      )
    }
  />
);

RefreshRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  isFundsFlowSetByUser: PropTypes.bool,
  redirectPath: PropTypes.string,
};

RefreshRoute.defaultProps = {
  component: null,
  isFundsFlowSetByUser: false,
  redirectPath: '/funds-flows',
};

export default RefreshRoute;
