import React from 'react';
import PropTypes from 'prop-types';
import { themeOptions } from '../../../../utils/propTypeHelper';
import NoPayoutsIcon from '../../../atoms/icons/NoPayoutsIcon';
import CheckMarkIcon from '../../../atoms/icons/CheckMarkIcon';
import styles from './BankAttachedPayoutsScreen.module.scss';
import notificationStyles from '../notification-bar.module.scss';

const BankAttachedPayoutsScreen = ({ theme }) => (
  <>
    <div className={notificationStyles.notification}>
      <CheckMarkIcon theme={theme} style={notificationStyles.check} />
      <p>Bank added!</p>
    </div>
    <div className={styles.container}>
      <NoPayoutsIcon theme={theme} />
      <p>You haven&apos;t received any payouts yet.</p>
    </div>
  </>
);

BankAttachedPayoutsScreen.propTypes = {
  theme: PropTypes.oneOf(themeOptions).isRequired,
};

export default BankAttachedPayoutsScreen;
