import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import styles from './Card.module.scss';
import { themeOptions } from '../../../utils/propTypeHelper';
import ConditionalWrapper from '../../molecules/conditional-wrapper/ConditionalWrapper';

const Card = ({
  children,
  variant,
  theme,
  buttonText,
  buttonDisabled,
  handleButtonClick,
  buttonLink,
}) => (
  <div className={classNames(styles.card, styles[variant])}>
    <div className={styles.container}>{children}</div>
    {!!buttonText && (
      <ConditionalWrapper
        condition={!!buttonLink}
        wrapper={c => <Link to={buttonLink}>{c}</Link>}
      >
        <button
          className={classNames(styles.button, styles[`btn-${theme}`])}
          type="button"
          disabled={buttonDisabled}
          onClick={handleButtonClick}
        >
          {buttonText}
        </button>
      </ConditionalWrapper>
    )}
  </div>
);

Card.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(['', 'noMargin']),
  theme: PropTypes.oneOf(themeOptions),
  buttonText: PropTypes.string,
  buttonDisabled: PropTypes.bool,
  handleButtonClick: PropTypes.func,
  buttonLink: PropTypes.string,
};

Card.defaultProps = {
  variant: '',
  theme: '',
  buttonText: null,
  buttonDisabled: false,
  handleButtonClick: null,
  buttonLink: null,
};

export default Card;
