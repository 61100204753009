/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Code from './Code';

const http = ({ fundingSourceUrl, bearerToken }) =>
  `POST ${fundingSourceUrl}/micro-deposits HTTP/1.1
Content-Type: application/vnd.dwolla.v1.hal+json
Accept: application/vnd.dwolla.v1.hal+json
Authorization: Bearer ${bearerToken}`;

const json = ({ amount1, amount2 }) =>
  `{
  "amount1": {
    "value": "${amount1}",
    "currency": "USD"
  },
  "amount2": {
    "value": "${amount2}",
    "currency": "USD"
  }
}`;

const VerifyMicroDepositsRequestCode = props => (
  <Code http={http(props)} json={json(props)} />
);

VerifyMicroDepositsRequestCode.propTypes = {
  fundingSourceUrl: PropTypes.string.isRequired,
  amount1: PropTypes.string.isRequired,
  amount2: PropTypes.string.isRequired,
  bearerToken: PropTypes.string.isRequired,
};

export default VerifyMicroDepositsRequestCode;
