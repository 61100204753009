import { connect } from 'react-redux';
import RefreshRoute from './RefreshRoute';
import config from '../config';

const mapStateToProps = state => ({
  isFundsFlowSetByUser: config.isCalculator
    ? state.calculator.isFundsFlowSetByUser
    : state.general.isFundsFlowSetByUser,
});

export default connect(mapStateToProps)(RefreshRoute);
