import React from 'react';
import PropTypes from 'prop-types';
import styles from './VerifyBankScreen.module.scss';
import notificationStyles from '../notification-bar.module.scss';
import CheckMarkIcon from '../../../atoms/icons/CheckMarkIcon';
import { themeOptions } from '../../../../utils/propTypeHelper';
import ThemeButton from '../../../atoms/theme-button/ThemeButton';
import { Heading } from '../../../atoms/typography/Typography';

const VerifyBankScreen = ({ theme, handleVerifyClicked }) => (
  <>
    <div className={notificationStyles.notification}>
      <CheckMarkIcon theme={theme} style={notificationStyles.check} />
      <p>Bank added!</p>
    </div>
    <div className={styles.container}>
      <Heading variant="theme" theme={theme} center>
        Verification Required
      </Heading>
      <p>
        You should receive two small micro-deposit amounts in your bank account.
      </p>
      <ThemeButton theme={theme} onClick={handleVerifyClicked}>
        Enter Amounts
      </ThemeButton>
    </div>
  </>
);

VerifyBankScreen.propTypes = {
  theme: PropTypes.oneOf(themeOptions).isRequired,
  handleVerifyClicked: PropTypes.func,
};

VerifyBankScreen.defaultProps = {
  handleVerifyClicked: null,
};

export default VerifyBankScreen;
