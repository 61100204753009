import PropTypes from 'prop-types';
import React from 'react';

const PageMapper = ({ componentMap, fundsFlow, variation, subStep }) => {
  const Component = variation
    ? componentMap[fundsFlow][variation][subStep]
    : componentMap[fundsFlow][subStep];
  return <Component />;
};

const arrayOfFuncs = PropTypes.arrayOf(PropTypes.func);
PageMapper.propTypes = {
  componentMap: PropTypes.objectOf(
    PropTypes.oneOfType([arrayOfFuncs, PropTypes.objectOf(arrayOfFuncs)])
  ).isRequired,
  fundsFlow: PropTypes.string.isRequired,
  variation: PropTypes.string,
  subStep: PropTypes.number.isRequired,
};

PageMapper.defaultProps = {
  variation: null,
};

export default PageMapper;
