export const isAppContainedInIFrame = () => window.self !== window.top;

export const scrollToTopOfIFrame = () => {
  if (
    isAppContainedInIFrame() &&
    window.parentIFrame &&
    window.innerWidth < 576
  ) {
    window.parentIFrame.scrollToOffset(0, 0);
  }
};
