import Dinero from 'dinero.js';

const ensureInteger = amount =>
  Number.isInteger(amount) ? amount : Math.ceil(amount);

const formatCurrency = amount => {
  const integerAmount = ensureInteger(Number(amount) * 100);
  return Dinero({ amount: integerAmount, currency: 'USD' }).toFormat();
};

export const formatCurrencyWithoutDollarSign = amount => {
  return formatCurrency(amount).substr(1);
};

export default formatCurrency;
