import React from 'react';
import styles from './ProfileIcon.module.scss';

const ProfileIcon = () => (
  <div className={styles.inner}>
    <div className={styles.head} />
    <div className={styles.body} />
  </div>
);

export default ProfileIcon;
