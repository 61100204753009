import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from './BankVerifiedSendToFriendScreen.module.scss';
import notificationStyles from '../notification-bar.module.scss';
import CheckMarkIcon from '../../../atoms/icons/CheckMarkIcon';
import { themeOptions } from '../../../../utils/propTypeHelper';
import { Heading, SubHeading } from '../../../atoms/typography/Typography';
import ThemeButton from '../../../atoms/theme-button/ThemeButton';
import ProfileIcon from '../../../atoms/icons/ProfileIcon';
import Card from '../../../atoms/card/Card';

const BankVerifiedSendToFriendScreen = ({
  theme,
  successNotificationText,
  customerName,
  nextLink,
}) => (
  <>
    <div className={notificationStyles.notification}>
      <CheckMarkIcon theme={theme} style={notificationStyles.check} />
      <p>{successNotificationText}</p>
    </div>
    <div className={styles.container}>
      <Heading variant="theme" theme={theme} center>
        Friends List
      </Heading>
      <Card>
        <div className={styles.iconWrapper}>
          <ProfileIcon />
        </div>
        <SubHeading variant="phone">{customerName}</SubHeading>

        <Link to={nextLink}>
          <ThemeButton theme={theme}>Send to Friend</ThemeButton>
        </Link>
      </Card>
    </div>
  </>
);

BankVerifiedSendToFriendScreen.propTypes = {
  theme: PropTypes.oneOf(themeOptions).isRequired,
  successNotificationText: PropTypes.string.isRequired,
  customerName: PropTypes.string.isRequired,
  nextLink: PropTypes.string.isRequired,
};

export default BankVerifiedSendToFriendScreen;
