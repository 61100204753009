/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Code from './Code';

const http = ({ bearerToken }) =>
  `POST https://api-sandbox.dwolla.com/customers HTTP/1.1
Content-Type: application/vnd.dwolla.v1.hal+json
Accept: application/vnd.dwolla.v1.hal+json
Authorization: Bearer ${bearerToken}`;

const json = ({
  firstName,
  lastName,
  email,
  dob,
  ssnLastFour,
  address1,
  city,
  state,
  postalCode,
}) =>
  `{
  "firstName": "${firstName}",
  "lastName": "${lastName}",
  "email": "${email}",
  "type": "personal",
  "ipAddress": "99.99.99.99",
  "dateOfBirth": "${dob}",
  "ssn": "${ssnLastFour}",
  "address1": "${address1}",
  "city": "${city}",
  "state": "${state}",
  "postalCode": "${postalCode}"
}`;

const VerifiedCustomerRequestCode = props => (
  <Code http={http(props)} json={json(props)} />
);

VerifiedCustomerRequestCode.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  dob: PropTypes.string.isRequired,
  ssnLastFour: PropTypes.string.isRequired,
  address1: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  postalCode: PropTypes.string.isRequired,
  bearerToken: PropTypes.string.isRequired,
};

export default VerifiedCustomerRequestCode;
