import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ThemeButton from '../../../atoms/theme-button/ThemeButton';
import { themeOptions } from '../../../../utils/propTypeHelper';

const accountTypeName = 'bankAccountType';
const checking = 'checking';
const savings = 'savings';

const UnverifiedBankScreen = ({
  theme,
  isValidInput,
  pending,
  elToFocus,
  bankAccountType,
  setBankAccountType,
  routingNumber,
  setRoutingNumber,
  accountNumber,
  setAccountNumber,
  accountAgain,
  setAccountAgain,
  name,
  setName,
  handleSubmitClicked,
}) => (
  <Form>
    <Form.Group controlId="type">
      <Form.Label style={{ display: 'block' }}>Account Type:</Form.Label>
      <Form.Check
        inline
        name={accountTypeName}
        id="checkingRadio"
        label="Checking"
        type="radio"
        value={checking}
        checked={bankAccountType === checking}
        onChange={e => setBankAccountType(e.target.value)}
        ref={elToFocus}
      />
      <Form.Check
        inline
        name={accountTypeName}
        id="savingsRadio"
        label="Savings"
        type="radio"
        value={savings}
        checked={bankAccountType === savings}
        onChange={e => setBankAccountType(e.target.value)}
      />
    </Form.Group>
    <Form.Group controlId="rn">
      <Form.Label>Routing Number:</Form.Label>
      <Form.Control
        type="input"
        value={routingNumber}
        onChange={e => setRoutingNumber(e.target.value)}
      />
    </Form.Group>
    <Form.Group controlId="an">
      <Form.Label>Account Number:</Form.Label>
      <Form.Control
        type="input"
        value={accountNumber}
        onChange={e => setAccountNumber(e.target.value)}
      />
    </Form.Group>
    <Form.Group controlId="an2">
      <Form.Label>Re-enter Account Number:</Form.Label>
      <Form.Control
        type="input"
        value={accountAgain}
        onChange={e => setAccountAgain(e.target.value)}
      />
    </Form.Group>
    <Form.Group controlId="name">
      <Form.Label>Account Nickname:</Form.Label>
      <Form.Control
        type="input"
        value={name}
        onChange={e => setName(e.target.value)}
      />
    </Form.Group>
    <ThemeButton
      theme={theme}
      disabled={!isValidInput}
      pending={pending}
      onClick={handleSubmitClicked}
    >
      Add Bank
    </ThemeButton>
  </Form>
);

UnverifiedBankScreen.propTypes = {
  theme: PropTypes.oneOf(themeOptions).isRequired,
  isValidInput: PropTypes.bool,
  pending: PropTypes.bool,
  elToFocus: PropTypes.element,
  bankAccountType: PropTypes.string,
  setBankAccountType: PropTypes.func,
  routingNumber: PropTypes.string,
  setRoutingNumber: PropTypes.func,
  accountNumber: PropTypes.string,
  setAccountNumber: PropTypes.func,
  accountAgain: PropTypes.string,
  setAccountAgain: PropTypes.func,
  name: PropTypes.string,
  setName: PropTypes.func,
  handleSubmitClicked: PropTypes.func,
};

UnverifiedBankScreen.defaultProps = {
  isValidInput: false,
  pending: false,
  elToFocus: null,
  bankAccountType: '',
  setBankAccountType: null,
  routingNumber: '',
  setRoutingNumber: null,
  accountNumber: '',
  setAccountNumber: null,
  accountAgain: '',
  setAccountAgain: null,
  name: '',
  setName: null,
  handleSubmitClicked: null,
};

export default UnverifiedBankScreen;
