/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { SubHeading } from '../../atoms/typography/Typography';
import FundsFlowsPage from './FundsFlowsPage';

const SelfServiceFundsFlowsPage = passThroughProps => (
  <FundsFlowsPage
    {...passThroughProps}
    PageSubHeading={() => (
      <SubHeading>
        How do payments work with your application? Choose a funds flow, then
        click “Next”.
      </SubHeading>
    )}
  />
);

export default SelfServiceFundsFlowsPage;
