import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ThemeButton from '../../../atoms/theme-button/ThemeButton';
import { Heading } from '../../../atoms/typography/Typography';
import { themeOptions } from '../../../../utils/propTypeHelper';

const MicroDepositsScreen = ({
  theme,
  isValidInput,
  pending,
  elToFocus,
  amount1,
  setAmount1,
  amount2,
  setAmount2,
  handleSubmitClicked,
}) => (
  <>
    <Heading variant="theme" theme={theme}>
      Enter Micro-Deposit Amounts
    </Heading>
    <Form>
      <Form.Group controlId="amount1">
        <Form.Label>Amount One:</Form.Label>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text>$</InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            type="number"
            step="0.01"
            value={amount1}
            onChange={e => setAmount1(e.target.value)}
            ref={elToFocus}
          />
        </InputGroup>
      </Form.Group>
      <Form.Group controlId="amount2">
        <Form.Label>Amount Two:</Form.Label>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text>$</InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            type="number"
            step="0.01"
            value={amount2}
            onChange={e => setAmount2(e.target.value)}
          />
        </InputGroup>
      </Form.Group>
      <ThemeButton
        theme={theme}
        disabled={!isValidInput}
        pending={pending}
        onClick={handleSubmitClicked}
      >
        Verify
      </ThemeButton>
    </Form>
  </>
);

MicroDepositsScreen.propTypes = {
  theme: PropTypes.oneOf(themeOptions).isRequired,
  isValidInput: PropTypes.bool,
  pending: PropTypes.bool,
  elToFocus: PropTypes.element,
  amount1: PropTypes.string,
  setAmount1: PropTypes.func,
  amount2: PropTypes.string,
  setAmount2: PropTypes.func,
  handleSubmitClicked: PropTypes.func,
};

MicroDepositsScreen.defaultProps = {
  isValidInput: false,
  pending: false,
  elToFocus: null,
  amount1: '',
  setAmount1: null,
  amount2: '',
  setAmount2: null,
  handleSubmitClicked: null,
};

export default MicroDepositsScreen;
